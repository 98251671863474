.section11-box { width: 80%; }
.sec11-back-mobile { display: none; }
.sec11-back-web { display: block; }
.accordion-item {
    border: none !important;
    margin-bottom: 1.5rem;
}
.accordion-header {
    background: white;
    border: 1px solid #ffb41a;
    color: black;
}
.accordion-button:focus {
    border-color: #ffb41a !important;
}
.accordion-button:not(.collapsed) {
    background-color: white !important;
    color: black;
}

/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .sec11-back-mobile { display: block; }
    .sec11-title { 
        font-size: 30px;
        margin-left: 1rem;
    }
    .sec11-back-web { display: none; }
    .sec11-content-title { font-size: 18px; }
    .section11-box { 
        width: 100%;
        padding: 0 1rem;
    }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}