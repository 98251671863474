.sec08-transition-box:hover {
    transition: all 1s;
    transform: translateY(-10%);
    cursor: pointer;
}
.guide-book { width: 228px !important; }
.guide-book:hover {
    cursor: pointer;
    opacity: 60%;
}
/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .section08 {  padding: 4rem 1rem 8rem !important;}
    .sec08-transition-box { display: block !important; }
    .sec08-img-box { 
        text-align: left !important;
        display: block;
    }
    .sec08-content-title { 
        font-size: 18px !important;
        line-height: 1.67 !important;
    }
    .sec08-content-text { 
        font-size: 14px !important;
        line-height: 2.14 !important;
    }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}