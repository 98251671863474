@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

* { 
font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif !important;
}
.width-100 { width: 100%; }
.width-90 { width: 90%; }
.width-80 { width: 80%; }
.width-70 { width: 70%; }
.width-60 { width: 60%; }
.width-50 { width: 50%; }
.width-30 { width: 30%; }
.max-width-1000 { max-width: 1000px; }
.max-width-1100 { max-width: 1100px; }
.max-width-1200 { max-width: 1200px; }
.max-width-1300 { max-width: 1300px; }
.max-width-1400 { max-width: 1400px; }
.max-width-1500 { max-width: 1500px; }
.height-100 { height: 100%; }
.height-80 { height: 80%; }
.height-50 { height: 50%; }
.height-30 { height: 30%; }
.height-fit { height: fit-content; }

.bottom-0 { bottom: 0; }
.top-0 { top: 0; }

.margin-auto { margin: auto; }

.font-bold { font-weight: bold; }
.text-white { color: white; }
.text-black { color: black; }
.text-yellow { color: #ffb41a; }
.font-80per { font-size: 80% !important; }
.font-12 { font-size: 12px; }
.font-14 { font-size: 14px; }
.font-16 { font-size: 16px; }
.font-18 { font-size: 18px; }
.font-20 { font-size: 20px; }
.font-22 { font-size: 22px; }
.font-24 { font-size: 24px; }
.font-26 { font-size: 26px; }
.font-30 { font-size: 30px; }
.font-36 { font-size: 36px; }
.font-40 { font-size: 40px; }
.font-50 { font-size: 50px; }
.font-weight-5 { font-weight: 500; }
.font-weight-6 { font-weight: 600; }
.font-weight-bold { font-weight: bold; }

/* Line Height */
.line-height-1 { line-height: 1; }
.line-height-15 { line-height: 1.5; }
.line-height-18 { line-height: 1.8; }
.line-height-2 { line-height: 2; }
.line-height-25 { line-height: 2.5; }
.line-height-3 { line-height: 3; }

/* Default margin */
.margin-none { margin: 0; }
.margin-auto { margin: 0 auto; }
.margin-05 { margin: 0.5rem; }
.margin-1 { margin: 1rem; }
.margin-2 { margin: 2rem; }
.margin-3 { margin: 3rem; }
.margin-4 { margin: 4rem; }
.margin-5 { margin: 5rem; }

.margin-left-05 { margin-left: 0.5rem; }
.margin-left-1 { margin-left: 1rem; }
.margin-left-2 { margin-left: 2rem; }
.margin-left-3 { margin-left: 3rem; }
.margin-left-4 { margin-left: 4rem; }
.margin-left-5 { margin-left: 5rem; }

.margin-right-05 { margin-right: 0.5rem; }
.margin-right-1 { margin-right: 1rem; }
.margin-right-2 { margin-right: 2rem; }
.margin-right-3 { margin-right: 3rem; }
.margin-right-4 { margin-right: 4rem; }
.margin-right-5 { margin-right: 5rem; }

.margin-top-05 { margin-top: 0.5rem; }
.margin-top-1 { margin-top: 1rem; }
.margin-top-2 { margin-top: 2rem; }
.margin-top-3 { margin-top: 3rem; }
.margin-top-4 { margin-top: 4rem; }
.margin-top-5 { margin-top: 5rem; }

.margin-bottom-0 { margin-bottom: 0; }
.margin-bottom-05 { margin-bottom: 0.5rem; }
.margin-bottom-1 { margin-bottom: 1rem; }
.margin-bottom-2 { margin-bottom: 2rem; }
.margin-bottom-3 { margin-bottom: 3rem; }
.margin-bottom-4 { margin-bottom: 4rem; }
.margin-bottom-5 { margin-bottom: 5rem; }
.margin-bottom-6 { margin-bottom: 6rem; }
.margin-bottom-7 { margin-bottom: 7rem; }
.margin-bottom-8 { margin-bottom: 8rem; }
.margin-bottom-9 { margin-bottom: 9rem; }

.margin-side-05 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.margin-side-1 {
    margin-left: 1rem;
    margin-right: 1rem;
}
.margin-side-2 {
    margin-left: 2rem;
    margin-right: 2rem;
}
.margin-side-3 {
    margin-left: 3rem;
    margin-right: 3rem;
}
.margin-side-4 {
    margin-left: 4rem;
    margin-right: 4rem;
}
.margin-side-5 {
    margin-left: 5rem;
    margin-right: 5rem;
}

.margin-top-bottom-05 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.margin-top-bottom-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.margin-top-bottom-2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.margin-top-bottom-3 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.margin-top-bottom-4 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.margin-top-bottom-5 {
    margin-top: 5rem;
    margin-bottom: 5rem;
}
/* Default padding */
.padding-05 { padding: 0.5rem; }
.padding-1 { padding: 1rem; }
.padding-2 { padding: 2rem; }
.padding-3 { padding: 3rem; }
.padding-4 { padding: 4rem; }
.padding-5 { padding: 5rem; }

.padding-left-05 { padding-left: 0.5rem; }
.padding-left-1 { padding-left: 1rem;}
.padding-left-2 { padding-left: 2rem;}
.padding-left-3 { padding-left: 3rem;}
.padding-left-4 { padding-left: 4rem;}
.padding-left-5 { padding-left: 5rem;}

.padding-right-05 { padding-right: 0.5rem; }
.padding-right-1 { padding-right: 1rem;}
.padding-right-2 { padding-right: 2rem;}
.padding-right-3 { padding-right: 3rem;}
.padding-right-4 { padding-right: 4rem;}
.padding-right-5 { padding-right: 5rem;}

.padding-top-05 { padding-top: 0.5rem; }
.padding-top-1 { padding-top: 1rem;}
.padding-top-2 { padding-top: 2rem;}
.padding-top-3 { padding-top: 3rem;}
.padding-top-4 { padding-top: 4rem;}
.padding-top-5 { padding-top: 5rem;}
.padding-top-6 { padding-top: 6rem;}
.padding-top-7 { padding-top: 7rem;}
.padding-top-8 { padding-top: 8rem;}
.padding-top-9 { padding-top: 9rem;}

.padding-bottom-05 { padding-bottom: 0.5rem; }
.padding-bottom-1 { padding-bottom: 1rem;}
.padding-bottom-2 { padding-bottom: 2rem;}
.padding-bottom-3 { padding-bottom: 3rem;}
.padding-bottom-4 { padding-bottom: 4rem;}
.padding-bottom-5 { padding-bottom: 5rem;}

.padding-side-05 { padding: 0 0.5rem; }
.padding-side-1 { padding: 0 1rem; }
.padding-side-2 { padding: 0 2rem; }
.padding-side-3 { padding: 0 3rem; }
.padding-side-4 { padding: 0 4rem; }
.padding-side-5 { padding: 0 5rem; }

.padding-top-bottom-05 { padding: 0.5rem 0; }
.padding-top-bottom-1 { padding: 1rem 0; }
.padding-top-bottom-2 { padding: 2rem 0; }
.padding-top-bottom-3 { padding: 3rem 0; }
.padding-top-bottom-4 { padding: 4rem 0; }
.padding-top-bottom-5 { padding: 5rem 0; }
.padding-top-bottom-6 { padding: 6rem 0; }
.padding-top-bottom-7 { padding: 7rem 0; }
.padding-top-bottom-8 { padding: 8rem 0; }
.padding-top-bottom-9 { padding: 9rem 0; }

.text-center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }

.display-flex { display: flex; }
.display-none { display: none; }

.position-relative { position: relative; }
.position-absolute { position: absolute; }

.align-items-center { align-items: center; }
.align-items-left { align-items: left; }
.align-items-right { align-items: right; }
.justify-content-center { justify-content: center; }
.justify-content-left { justify-content: left; }
.justify-content-right { justify-content: right; }

/* body fixed */
.body-fix {
    position: fixed;
    width: 100%;
    overflow-y: scroll;
}
/* fadein */
.off-canvas-fadein {
    animation: fadein 0.5s;
    -moz-animation: fadein 0.5s; /* Firefox */
    -webkit-animation: fadein 0.5s; /* Safari and Chrome */
    -o-animation: fadein 0.5s; /* Opera */
}