.sec03-back-mobile { display: none; }
.sec03-back-web { display: block; }

/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .sec03-back-mobile { display: block; }
    .sec03-back-web { display: none; }
    .sec03-title { font-size: 30px; }
    .sec03-text { font-size: 16px; }
    .sec03-title-box { width: 90%; }
    .sec03-icon-box { 
        width: 95%;
        margin: auto;
    }
    .sec02-icon01-box { margin-right: 2rem; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}
