.header-mobile { display: none; }
.header-web { display: block; }
.top-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-box {
    padding: 1rem 2rem;
    width: 100%;
}
.header-inner01, .header-inner03 { width: 10%; }
.header-inner02 { width: 80%; }
.header-menu {
    border: none;
    padding: 0.2rem 1rem;
    background-color: white;
}
.header-menu:hover {
    font-weight: 600;
    color: #FFB41A;
}
.language-select {
    width: 100px;
    padding: 0.2rem 1rem;
}

/* side menu */
.off-canvas { 
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    z-index: 1;
}
.mobile-menu-btn {
    border: none;
    background: none;
    color: white;
    padding: 1rem;
    font-weight: 600;
}

/* media screen */
@media screen and (max-width: 1250px){
    .header-mobile { display: block; }
    .header-web { display: none; }
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .header-mobile { display: block; }
    .header-web { display: none; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
/* desktop */
@media screen and (min-width: 769px){
}