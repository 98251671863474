.section06-back {
    width: 100%;
    height: 600px;
}
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .section06 { padding: 5rem 0; }
    .sec06-title { 
        margin-left: 1rem;
        font-size: 30px;
    }
    .sec06-content-box { display: block !important; }
    .sec06-contents { margin-bottom: 4rem !important; }
    .sec06-content-img { width: 60% !important; }
    .sec06-content-layout { padding: 0 4rem; }
    .sec06-content-title { font-size: 22px !important; }
    .sec06-content-text { 
        font-size: 16px !important;
        line-height: 1.5 !important;
        font-weight: 500 !important;
    }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}