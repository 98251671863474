.section01-back-box, .section11-back-box {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sec01-back-mobile, .sec01-contents-mobile { display: none; }
.background-img { max-height: 1102px; }
.sec01-text-box { max-width: 842px; }
/* sec01 fadein */
.sec01-content-box {
    /* display: inline-block; */
    animation: fadein 6s;
    -moz-animation: fadein 6s; /* Firefox */
    -webkit-animation: fadein 6s; /* Safari and Chrome */
    -o-animation: fadein 6s; /* Opera */
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .sec01-back-mobile, .sec01-contents-mobile { display: block; }
    .sec01-back-web, .sec01-contents-web { display: none; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}
