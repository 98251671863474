.section05-img { width: 1800px; }
.sec05-content-box {
    border: solid 1px #000;
    background-color: white;
}
.sec05-content-row { 
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.col-6 { width: 47.5% !important; }
.sec05-content-box:hover {
    background-color: #ffb41a;
    cursor: pointer;
}
.sec05-back-mobile { display: none !important; }

@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .section05 { 
        padding-top: 2rem;
        padding-bottom: 0;
    }
    .sec05-title { 
        position: absolute;
        top: 6rem;
        z-index: 1;
        font-size: 30px;
        margin-left: 1rem;
        margin-bottom: 0;
    }
    .sec05-back-mobile { display: block !important; }
    .sec05-back-web { display: none !important; }
    .contents-layout { 
        width: 90% !important;
        top: 13rem !important;
    }
    .content-line-box { display: block !important; }
    .sec05-line-box02 { margin-bottom: 0 !important; }
    .sec05-content-box { 
        height: 236px !important;
        padding: 0.5rem !important;
    }
    .content-title { font-size: 20px !important; }
    .content-text { font-size: 90% !important; }
    .content-text-en { font-size: 80% !important; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}