.whitpaper-btn {
    border: none;
    background-color: #ffb41a;
    color: white;
    padding: 1rem 6rem;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
}
.whitpaper-btn:hover {
    transition: all 1s;
    transform: translateY(-10%);
    cursor: pointer;
}

/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .whitpaper-btn { width: 100% !important; }
    .sec12-title {
        font-size: 30px;
        margin-left: 1rem;
        margin-bottom: 4rem;
    }
    .sec12-content-box01, .sec12-content-box02 { display: block; }
    .sec12-content-box01 { padding-bottom: 0; }
    .sec12-content-box02 { padding-top: 0; }
    .doc-en, .doc-cn, .doc-kr, .doc-jp {
        text-align: center;
        margin-bottom: 1.5rem;
        padding: 0;
    }
    .sec12-doc-img-box { margin-top: 2rem; }
    .sec12-doc-img { width: 90%; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px){
}