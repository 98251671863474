@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .section07 { padding: 0 2rem !important; }
    .sec07-title { font-size: 26px !important; }
    .sec07-content {
        line-height: 1.88 !important;
        font-weight: normal !important;
        font-size: 16px !important;
    }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}