/* sec03 fadein */
.section04-back-box {
    /* display: inline-block; */
    animation: fadein 6s;
    -moz-animation: fadein 6s; /* Firefox */
    -webkit-animation: fadein 6s; /* Safari and Chrome */
    -o-animation: fadein 6s; /* Opera */
}
.sec04-img-mobile { display: none; }
.sec04-img-web { display: block; }

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .sec04-title { 
        font-size: 30px;
        margin-left: 1rem;
        margin-bottom: 4rem;
    }
    .section04-back-box { width: 90%; }
    .sec04-img-mobile { display: block; }
    .sec04-img-web { display: none; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}