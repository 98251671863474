.map-icon {
    width: 318px !important;
    height: 413px !important;
}
.map-icon:hover {
    transition: all 1s;
    transform: translateY(-10%);
    cursor: pointer;
}

/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .section10 { padding-bottom: 6rem; }
    .sec10-title {
        margin-left: 1rem;
        font-size: 30px;
    }
    .sec10-content-box { display: block; }
    .map-img-box { 
        margin: 0 auto;
        padding: 1rem 0;
    }
    .map-icon { width: 75%; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px){
}