.footer-sns-link {
    text-decoration: none;
}
.footer-sns-link:hover {
    cursor: pointer;
    color: white;
}
.mobile-layout { 
    display: none;
    padding: 2rem 1.5rem;
    text-align: left;
}
.footer-logo-img {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .mobile-layout { display: block !important; }
    .web-layout { display: none; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}
