.section02-video {
    /* max-width: 973px; */
    /* max-height: 491px; */
    height: 491px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.section02 { padding: 9rem 0; }
.sec02-video-mobile { display: none; }
.sec02-video-web { display: block; }

/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .section02 { padding: 0; }
    .sec02-video-mobile { display: block; }
    .sec02-video-web { display: none; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}
