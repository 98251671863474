/* media screen */
@media screen and (max-width: 1300px){
}
/* 모바일 화면 css */
@media screen and (max-width: 768px){
    .sec09-content-box { display: block; }
    .sec09-title { font-size: 30px }
    .contents { margin: auto; }
    .contnet-img01, .contnet-img03 { width: 70%; }
    .content-img02 { width: 40%; }
    .contnet-img01, .content-img02, .contnet-img03 { margin-bottom: 0.5rem; }
}
/* iphone 6/7/8 */
@media screen and (max-width: 400px){
}
/* iphone SE */
@media screen and (max-width: 350px){
}
/* galaxy fold */
@media screen and (max-width: 300px){
}
    /* desktop */
@media screen and (min-width: 769px) {
}